import styles from './Header.module.css';

interface Props {
  onShowSettings: () => void;
}

function Header({onShowSettings}: Props) {
  return (
    <div className={styles.container}>
      <span className="has-text-weight-semibold">HN Lite</span>
      <button className="button is-small is-inverted" onClick={onShowSettings}>Settings</button>
    </div>
  );
}

export default Header;
