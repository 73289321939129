import {useMemo, useState} from 'react';
import fetchTopStories from './fetchTopStories';
import useResource from './useResource';
import StoriesList from './StoriesList';
import usePersistedState from './usePersistedState';
import SettingsModal from './SettingsModal';
import styles from './App.module.css';
import Header from './Header';

const BLACKLIST_KEYWORDS_STORAGE_KEY = 'BLACKLISTED_KEYWORDS';

async function fetchTop40Stories() {
  return await fetchTopStories(40);
}

function App() {
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [blacklistedKeywords, setBlacklistedKeywords] = usePersistedState<string[]>(
    [],
    BLACKLIST_KEYWORDS_STORAGE_KEY,
  );

  const response = useResource(fetchTop40Stories);

  const stories = useMemo(() => {
    if (response.state !== 'RESOLVED') {
      return [];
    }

    const lowerCaseKeywords = blacklistedKeywords.map(kw => kw.toLocaleLowerCase());

    return response
      .value
      .filter(story => {
        const lowerCaseTitle = story.title.toLocaleLowerCase();
        return lowerCaseKeywords.every(kw => !lowerCaseTitle.includes(kw));
      })
      .slice(0, 30);
  }, [response, blacklistedKeywords]);

  if (response.state === 'LOADING') {
    return <div>Loading...</div>;
  }

  if (response.state === 'REJECTED') {
    return <div>Something went wrong.</div>;
  }

  return (
    <div className={styles.container}>
      <Header onShowSettings={() => setShowSettingsModal(true)} />
      <StoriesList stories={stories} />
      <SettingsModal
        isShown={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
        blacklistedKeywords={blacklistedKeywords}
        onBlacklistedKeywordsChange={setBlacklistedKeywords}
      />
    </div>
  );
}

export default App;
