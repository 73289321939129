import {useCallback, useState} from 'react';

type ReturnType<T> = [T, (value: T) => void];

function readPersistedState<T>(storageKey: string): T | undefined {
  const storageValue = localStorage.getItem(storageKey);
  if (storageValue == null) {
    return undefined;
  }
  const [, json] = storageValue.split(':', 2);
  return JSON.parse(json);
}

function persistState<T>(storageKey: string, value: T, version: number) {
  const storageValue = `${version}:${JSON.stringify(value)}`;
  localStorage.setItem(storageKey, storageValue);
}

// Similar to useState, but the values are also persisted into local storage
// - initialValue: If the state has not been persisted before, this will be the
//   initial value of the state.
// - storageKey: Key used to store this state in local storage. Make sure this
//   value is globally unique.
// - version: Used to support versioning (i.e., if the schema if the state changes
//   we can know which version it was persisted from).
//
// Limitations:
// - The current implementation only writes to local storage. If the local storage
//   is changed (i.e., if this hook is used more than once per app, or the value
//   is mutated by another instance of the app in another tab), it will not
//   refresh the value.
// - Versioning and schema migration is not implemented yet. The version number is
//   written into storage. But when reading it back, we ignore the version number.
// - Local storage stores the values as strings. The current serializer and
//   deserializer are JSON.stringify and JSON.parse.
//
// Implementation details:
// - The value is stored as `${version}:${JSON.stringify(value)}`.
function usePersistedState<T>(initialValue: T, storageKey: string, version: number = 0): ReturnType<T> {
  const [state, setState] = useState(() => {
    const persistedState = readPersistedState<T>(storageKey);
    return persistedState ?? initialValue;
  });

  const setAndPersistState = useCallback((updatedState: T) => {
    setState(updatedState);
    persistState(storageKey, updatedState, version);
  }, [storageKey, version]);

  return [state, setAndPersistState];
}

export default usePersistedState;
