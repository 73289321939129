interface BaseItem {
  id: string;
  by: string;
  time: number;
  url: string;
}

export interface Story extends BaseItem {
  type: "story";
  title: string;
  text?: string;
  kids: string[];
  descendants: number;
  score: number;
}

async function fetchTopStoryIDs(): Promise<string[]> {
  const resp = await fetch('https://hacker-news.firebaseio.com/v0/topstories.json');
  return await resp.json();
}

async function fetchStory(id: string): Promise<Story> {
  const resp = await fetch(`https://hacker-news.firebaseio.com/v0/item/${id}.json`);
  return await resp.json();
}

async function fetchTopStories(limit: number): Promise<Story[]> {
  const ids = await fetchTopStoryIDs();
  const truncatedIDs = ids.slice(0, limit);
  return await Promise.all(
    truncatedIDs.map(id => fetchStory(id)),
  );
}

export default fetchTopStories;
