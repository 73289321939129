import {useEffect, useState} from 'react';
import usePromise, {PromiseState} from './usePromise';

const INITIAL_PROMISE = new Promise(() => {});

function useResource<T>(generator: () => Promise<T>): PromiseState<T> {
  const [promise, setPromise] = useState((INITIAL_PROMISE as Promise<T>));

  const promiseState = usePromise(promise);

  useEffect(() => {
    setPromise(generator());
  }, [generator]);

  return promiseState;
}

export default useResource;
