import {Story} from './fetchTopStories';
import styles from './StoryItem.module.css';

interface Props {
  story: Story;
}

function CommentsLink({id, count}: {id: string, count: number}) {
  const label = count === 1 ? 'comment' : 'comments';
  const link = `https://news.ycombinator.com/item?id=${id}`;

  return (
    <a href={link}>{count} {label}</a>
  );
}

function StoryItem({story}: Props) {
  return (
    <div className={styles.container}>
      <div>
        <a href={story.url}>{story.title}</a>
      </div>
      <div className="is-size-7">
        {story.score} points | <CommentsLink id={story.id} count={story.descendants} />
      </div>
    </div>
  );
}

export default StoryItem;
