import {useEffect, useState} from 'react';

export type PromiseState<T> =
  | { state: 'LOADING' }
  | { state: 'RESOLVED', value: T }
  | { state: 'REJECTED', error: Error }

function usePromise<T>(promise: Promise<T>): PromiseState<T> {
  const [state, setState] = useState<PromiseState<T>>({state: 'LOADING'});

  useEffect(() => {
    let cancelled = false;

    promise.then(
      value => {
        if (cancelled) {
          return;
        }

        setState({state: 'RESOLVED', value});
      },
      error => {
        if (cancelled) {
          return;
        }

        setState({state: 'REJECTED', error});
      },
    );

    return () => {
      cancelled = true;
    };
  }, [promise]);

  return state;
}

export default usePromise;
