import {Story} from './fetchTopStories';
import StoryItem from './StoryItem';

interface Props {
  stories: Story[];
}

function StoriesList({stories}: Props) {
  return (
    <div>
      {stories.map(story => <StoryItem key={story.id} story={story} />)}
    </div>
  );
}

export default StoriesList;
