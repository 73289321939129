import {useState} from 'react';
import classNames from './classNames';

interface Props {
  isShown: boolean;
  onHide: () => void;
  blacklistedKeywords: string[];
  onBlacklistedKeywordsChange: (keywords: string[]) => void;
}

function SettingsModal({isShown, onHide, blacklistedKeywords, onBlacklistedKeywordsChange}: Props) {
  const [keyword, setKeyword] = useState('');

  const onAddKeyword = () => {
    onBlacklistedKeywordsChange([
      ...blacklistedKeywords,
      keyword,
    ]);
    setKeyword('');
  };

  const onDeleteKeyword = (keyword: string) => {
    onBlacklistedKeywordsChange(
      blacklistedKeywords.filter(kw => kw !== keyword),
    );
  };

  return (
    <div className={classNames('modal', isShown && 'is-active')}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Settings</p>
          <button className="delete" onClick={onHide} />
        </header>
        <section className="modal-card-body">
          <label>Blacklisted Keywords</label>
          <div className="tags">
            {blacklistedKeywords.map((kw, i) => (
              <span key={i} className="tag">
                {kw}
                <button className="delete is-small" onClick={() => onDeleteKeyword(kw)} />
              </span>)
            )}
          </div>
          <div className="field has-addons">
            <div className="control is-flex-grow-1">
              <input type="text" className="input" value={keyword} onChange={e => setKeyword(e.target.value)} />
            </div>
            <div className="control">
              <button className="button is-info" onClick={onAddKeyword}>
                Add Keyword
              </button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-primary" onClick={onHide}>OK</button>
        </footer>
      </div>
    </div>
  );
}

export default SettingsModal;
